export enum INITIATOR {
  employee_web = 'employee_web',
  b2c_web = 'b2c_web',
  employee_mobile = 'employee_mobile',
  employer_web = 'employer_web',
  admin_web = 'admin_web',
  ussd = 'ussd',
  vendor_web = 'vendor_web',
  other = 'other',
}

export enum Token {
  lastLogin = '@@API_MANAGER:LAST_LOGIN@@',
  tokenId = '@@API_MANAGER:USER_API_TOKEN@@',
  intervalID = '@@API_MANAGER:INTERVAL_ID@@',
}

export enum OrganizationTypes {
  parent = 'Parent Organization',
  child = 'child Organization'
}